import { DocumentContext } from 'next/document'

import { getUserSSR } from '~/utils/getUserSSR'
import { globalBoostrapPropsWrapper, globalBootstrap, GlobalBootstrapWrapper } from '~/utils/globalBootstrap'

import { LoginPage } from '~/components/User/LoginPage/LoginPageView'
import { paths } from '~/constants'

export const getServerSideProps = async (ctx: DocumentContext) => {
  const { apolloClient } = await globalBootstrap({ ctx })

  const { user } = await getUserSSR({ apolloClient })

  const redirect = {
    destination: paths.home,
    permanent: false,
  }

  return globalBoostrapPropsWrapper({ apolloClient }, { props: {}, ...(user ? { redirect } : {}) })
}

export default GlobalBootstrapWrapper(LoginPage)
