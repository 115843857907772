import { useRouter } from 'next/router'

import { paths } from '~/constants'

import { LoginForm } from '../LoginModal/LoginForm'
import { LoginPageWrapper } from '../LoginModal/LoginModalShards'

export const LoginPage = () => {
  const router = useRouter()
  const { redirectTo } = router.query
  const redirectUrl = redirectTo && typeof redirectTo === 'string' ? redirectTo : paths.home

  return (
    <LoginPageWrapper>
      <LoginForm
        handleClose={() => {
          router.push(redirectUrl)
        }}
        onLogin={() => router.push(redirectUrl)}
      />
    </LoginPageWrapper>
  )
}
